.link-white {
  color: white;
  text-decoration: underline;
}

.leave-campaign-text a {
  cursor: pointer;
}

.terms-link {
  cursor: pointer;
}

app-publication-template-preview {
  p {
    margin: 0;
  }
}
