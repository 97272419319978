/* fallback */
@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url(/assets/fonts/MaterialIcons/material-icons-202404.woff2)
    format("woff2");
}

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

/*
 * Typography
 */

@font-face {
  font-family: "Nunito Bold";
  src:
    url(/assets/fonts/Bold/Nunito-Bold.woff2) format("woff2"),
    url(/assets/fonts/Bold/Nunito-Bold.woff) format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Nunito Light";
  src:
    url(/assets/fonts/Light/Nunito-Light.woff2) format("woff2"),
    url(/assets/fonts/Light/Nunito-Light.woff) format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Nunito";
  src:
    url(/assets/fonts/Regular/Nunito-Regular.woff2) format("woff2"),
    url(/assets/fonts/Regular/Nunito-Regular.woff) format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Nunito Semibold";
  src:
    url(/assets/fonts/Medium/Nunito-SemiBold.woff2) format("woff2"),
    url(/assets/fonts/Medium/Nunito-SemiBold.woff) format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src:
    url(/assets/fonts/Regular/Roboto-Regular.woff2) format("woff2"),
    url(/assets/fonts/Regular/Roboto-Regular.woff) format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto Light";
  src:
    url(/assets/fonts/Light/Roboto-Light.woff2) format("woff2"),
    url(/assets/fonts/Light/Roboto-Light.woff) format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto Medium";
  src:
    url(/assets/fonts/Medium/Roboto-Medium.woff2) format("woff2"),
    url(/assets/fonts/Medium/Roboto-Medium.woff) format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto Bold";
  src:
    url(/assets/fonts/Bold/Roboto-Bold.woff2) format("woff2"),
    url(/assets/fonts/Bold/Roboto-Bold.woff) format("woff");
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  src:
    url(/assets/fonts/Light/OpenSans-Light.woff2) format("woff2"),
    url(/assets/fonts/Light/OpenSans-Light.woff) format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  src:
    url(/assets/fonts/Regular/OpenSans-Regular.woff2) format("woff2"),
    url(/assets/fonts/Regular/OpenSans-Regular.woff) format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  src:
    url(/assets/fonts/Medium/OpenSans-Medium.woff2) format("woff2"),
    url(/assets/fonts/Medium/OpenSans-Medium.woff) format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  src:
    url(/assets/fonts/SemiBold/OpenSans-SemiBold.woff2) format("woff2"),
    url(/assets/fonts/SemiBold/OpenSans-SemiBold.woff) format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  src:
    url(/assets/fonts/Bold/OpenSans-Bold.woff2) format("woff2"),
    url(/assets/fonts/Bold/OpenSans-Bold.woff) format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans Bold";
  src:
    url(/assets/fonts/Bold/OpenSans-Bold.woff2) format("woff2"),
    url(/assets/fonts/Bold/OpenSans-Bold.woff) format("woff");
}

@font-face {
  font-family: "Roboto Black";
  src:
    url(/assets/fonts/Black/Roboto-Black.woff2) format("woff2"),
    url(/assets/fonts/Black/Roboto-Black.woff) format("woff");
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto Slab Bold";
  src:
    url(/assets/fonts/Bold/RobotoSlab-Bold.woff2) format("woff2"),
    url(/assets/fonts/Bold/RobotoSlab-Bold.woff) format("woff");
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto Slab";
  src:
    url(/assets/fonts/Regular/RobotoSlab-Regular.woff2) format("woff2"),
    url(/assets/fonts/Regular/RobotoSlab-Regular.woff) format("woff");
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto Condensed Bold";
  src:
    url(/assets/fonts/Bold/RobotoCondensed-Bold.woff2) format("woff2"),
    url(/assets/fonts/Bold/RobotoCondensed-Bold.woff) format("woff");
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto Condensed";
  src:
    url(/assets/fonts/Regular/RobotoCondensed-Regular.woff2) format("woff2"),
    url(/assets/fonts/Regular/RobotoCondensed-Regular.woff) format("woff");
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Merriweather";
  src:
    url(/assets/fonts/Regular/Merriweather-Regular.woff2) format("woff2"),
    url(/assets/fonts/Regular/Merriweather-Regular.woff) format("woff");
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat Bold";
  src:
    url(/assets/fonts/Bold/Montserrat-Bold.woff2) format("woff2"),
    url(/assets/fonts/Bold/Montserrat-Bold.woff) format("woff");
  font-style: normal;
  font-display: swap;
}

body {
  font-family: "Open Sans", sans-serif;
  font-size: 100%; /* browser's font-size setting */
  line-height: 1.5;
  color: $body-font-color;
  font-feature-settings: "kern", "liga";
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: subpixel-antialiased;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Nunito", sans-serif;
  font-weight: normal;
  color: $body-font-color;
  line-height: 29px;
}

.text-small {
  font-size: 0.875em;
}

.text-dark-grey {
  color: color(gray, 400);
}

.text-align--center {
  text-align: center;
}
