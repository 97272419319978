@import "variables";

@mixin container() {
  max-width: $container-width;
  margin-right: auto;
  margin-left: auto;
}

@mixin sr-only() {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

@mixin clearfix() {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

@mixin dark-post-badge() {
  position: relative;
  text-transform: uppercase;
  background: lighten(color(orange, 500), 30%);
  color: var(--theme-color-primary);
  padding: 7px 12px;
  border-radius: 20px;
  font-size: 10px;
  line-height: 14px;
  img {
    margin-right: 3px;
    height: 14px;
    width: auto;
    vertical-align: top;
  }
}

@mixin campaign-posts-list() {
  .post {
    display: flex;
    margin-bottom: 20px;
  }

  .post-info,
  app-facebook-post-mock-up {
    width: 494px;
  }

  .post-stats-list {
    min-width: 325px;
    max-width: 272px;
  }

  .post-stats {
    background-color: color(gray, 700);
    color: white;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    height: 100%;
    min-width: 325px;

    .post-stats-content {
      padding: 16px 26px 26px;

      &.not-promoted {
        padding-top: 26px;
      }
    }

    h2 {
      color: inherit;
      font-size: 16px;
      margin-top: 0px;
      margin-bottom: 0;
    }

    .stat-last-updated {
      display: block;
      margin-bottom: 8px;
      font-size: 14px;
      line-height: 19px;
      margin-top: 10px;

      a {
        text-decoration: none;
        color: var(--theme-color-primary);
      }

      a:hover {
        text-decoration: underline;
      }
    }
    p {
      margin-top: 0;

      &:last-child {
        margin-top: auto;
        margin-bottom: 0;
      }

      img {
        margin-right: 5px;
        top: 2px;
      }

      .stat-date {
        color: white;
        font-weight: bold;
        font-size: 18px;
        line-height: 24px;
      }

      .stat-text {
        cursor: pointer;
        color: color(gray, 200);
        font-family: "Open Sans";
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 19px;

        img {
          margin-right: $margin-small;
        }
        mat-icon {
          font-size: 18px;
          width: 18px;
          height: 18px;
          margin-right: 8px;
          opacity: 0.3;
        }
      }

      .stat-number {
        color: white;
        font-family: "Open Sans";
        font-size: 18px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 24px;
      }

      .stat-tooltip {
        padding-left: 10px;
      }

      &.single-stat {
        min-height: 70px;
        .stat-text:hover {
          text-decoration: underline;
        }
      }

      &.single-stat--short-icon {
        padding-top: 4px;
      }

      &.single-stat--shorter-icon {
        padding-top: 6px;
      }

      &.single-stat--no-icon {
        padding-top: 1px;
      }
    }
    .promoted-badge {
      background: color(gray, 900);
      font-family: $body-font;
      font-size: 9px;
      font-weight: 700;
      color: var(--theme-color-primary);
      line-height: 16px;
      padding: 4px 14px;
      text-align: right;
      text-transform: uppercase;

      &.badge-error {
        background: color(red, 600);
        color: $color-white;
      }
    }

    .stats-metrics-wrap--first {
      padding-top: 20px;
    }

    .stats-metrics-wrap {
      display: flex;
      padding-bottom: 10px;
      min-width: 260px;
    }

    .stats-metrics-column {
      flex: 1;
    }
    .stats-metrics-column--right {
      padding-left: 16px;
    }
  }
  @media only screen and (max-width: $ipad-breakpoint) {
    .post-info {
      width: 55%;
    }
  }
}

@mixin fb-post() {
  .fb-post {
    width: 494px;
    max-width: 100%;
    font-size: 14px;
    text-align: left;
    line-height: 19px;
    letter-spacing: -0.01em;
    font-family:
      Helvetica Neue,
      Helvetica,
      Arial,
      sans-serif;
    background-color: white;
    border-radius: 3px;
    border: 1px solid #dddfe2;
    min-height: 530px;
  }

  .fb-post--carousel {
    min-height: 550px;
  }

  .fb-post--for-list {
    min-height: 550px;
  }

  .fb-post--full-border {
    border-right: 1px solid #dddfe2;
  }

  .fb-user {
    padding: 12px;

    &:after {
      content: "";
      clear: both;
      display: table;
    }

    .fb-user-image {
      float: left;
      border-radius: 100%;
      width: 38px;
      height: 38px;
    }

    div {
      margin-left: 48px;
    }

    .fb-user-name {
      font-weight: bold;
      color: #365899;
      display: block;
    }

    .fb-user-location {
      font-size: 12px;
      line-height: 16px;
      color: #90949c;
      display: block;
      font-size: 12px;

      img {
        top: 1px;
        margin-left: 3px;
      }
    }
  }

  .fb-text {
    padding: 0 12px;
    color: #1d2129;
  }

  .fb-image {
    margin: 12px 0;

    img {
      width: 100%;
      max-width: 100%;
      height: auto;
    }
  }

  .fb-reactions {
    display: flex;
    align-items: center;
    color: #9197a4;
    font-size: 12px;
    font-weight: bold;
    justify-content: space-between;
    padding: 0 16px;

    .fb-likes-section {
      display: flex;
      align-items: center;
    }

    .fb-comments-section {
      justify-self: flex-end;
    }
  }

  .fb-actions {
    margin: 12px;
    padding-top: 12px;
    border-top: 1px solid color(gray, 200);

    ul {
      margin: 0;
      padding: 0;
      list-style-type: none;
      display: flex;
      justify-content: space-around;

      li {
        display: inline-block;
        padding: 0 12px;
        font-size: 13px;
        line-height: 14px;
        color: #616770;
        font-weight: bold;

        img {
          vertical-align: text-bottom;
          margin-right: 4px;
        }
      }
    }
  }
}

@mixin facebook-instagram-preview-button() {
  .facebook-instagram-preview-button {
    box-sizing: border-box;
    height: 32px;
    border: 1px solid #dddfe2;
    border-radius: 5px;
    background-color: $color-white;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 19px;
    padding-right: 8px;
    padding-left: 8px;
    cursor: pointer;
    span {
      color: color(gray, 900);
      font-family: "Open Sans";
      font-size: 14px;
      letter-spacing: 0;
      font-weight: 100;
    }
    img {
      margin-left: 8px;
      width: 15px;
      height: 15px;
    }
  }
  button:focus {
    outline: 0;
  }
}

@mixin flex-centered-content() {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin text-ellipsis($lines: 1) {
  overflow: hidden;

  @if ($lines == 1) {
    white-space: nowrap;
    text-overflow: ellipsis;
  } @else {
    // Ellipsis on second line
    // See: https://css-tricks.com/almanac/properties/l/line-clamp/
    display: -webkit-box;
    -webkit-line-clamp: $lines;
    -webkit-box-orient: vertical;
  }
}
