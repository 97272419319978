#toast-container .toast {
  padding: $padding-default $padding-large $padding-default ($padding-large * 2);
  color: white;
  border-radius: 4px;
  box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.1) !important;
  position: relative;
  font-weight: 300;

  &.success {
    background-color: #82c343;
    border: 1px solid #6da537;
    background-image: url("/assets/images/icons/ic_toast_confirmation.svg");
  }

  &.info {
    background-color: color(gray, 600);
    border: 1px solid color(gray, 800);
    background-image: url("/assets/images/icons/ic_toast_info.svg");
  }

  &.warning {
    background-color: #f69a38;
    border: 1px solid #90202e;
    background-image: url("/assets/images/icons/ic_toast_warn.svg");
  }

  &.error {
    background-color: #ca1a30;
    border: 1px solid #90202e;
    background-image: url("/assets/images/icons/ic_toast_warn.svg");
  }

  .toast-close-button {
    font-weight: 400;
    position: absolute;
    right: $padding-default;
    top: 50%;
    margin-top: -10px;
    font-size: 20px;
    line-height: 20px;
    color: white;
    float: none;
    text-shadow: none;
    opacity: 1;

    &:hover {
      opacity: 0.8;
    }
  }
}
