// Colors
$color-black: #000;
$color-white: #fff;

$colors: (
  blue: (
    50: #eff6ff,
    100: #dbeafe,
    200: #bfdbfe,
    300: #93c5fd,
    400: #60a5fa,
    500: #3b82f6,
    600: #2563eb,
    700: #1d4ed8,
    800: #1e40af,
    900: #1e3a8a,
  ),
  bluegray: (
    50: #e7ebff,
    800: #3d405b,
    900: #31344b,
  ),
  chart: (
    amber: #facc15,
    blue: #3b82f6,
    green: #31ca5e,
    indigo: #6366f1,
    orange: #f59e0b,
    pink: #ec4899,
    purple: #a855f7,
    red: #fa3d15,
    teal: #14b8a6,
  ),
  gray: (
    50: #fafafa,
    100: #f5f5f5,
    200: #e5e5e5,
    300: #d4d4d4,
    400: #a3a3a3,
    500: #737373,
    600: #525252,
    700: #404040,
    800: #262626,
    900: #171717,
  ),
  green: (
    50: #f0fdf4,
    100: #dcfce7,
    200: #bbf7d0,
    300: #86efac,
    400: #4ade80,
    500: #22c55e,
    600: #16a34a,
    700: #15803d,
    800: #166534,
    900: #14532d,
  ),
  orange: (
    50: #fffaf6,
    100: #ffefe4,
    200: #fee0ca,
    300: #ffc69c,
    400: #f79f5d,
    500: #f28a3d,
    600: #c26a29,
    700: #9c5725,
    800: #7d451c,
    900: #66340e,
  ),
  red: (
    50: #fef2f2,
    100: #fee2e2,
    200: #fecaca,
    300: #fca5a5,
    400: #f87171,
    500: #ef4444,
    600: #dc2626,
    700: #b91c1c,
    800: #991b1b,
    900: #7f1d1d,
  ),
);

// Legacy colors - DON'T USE
$blue: #3b5998;
$green: #1dbf77;
$black: #0a0a0a;

$green-state-in-progress: #00d25f;
$blue-state-pending: #3d80f2;
$yellow: #ffca48;
$pink: #ff947a;
$instagram-background: #f7efff;
$content-background: #f7f7f7;

// CSS `filter` fill colors, used to change color to images.
// See: https://codepen.io/sosuke/pen/Pjoqqp
$color-filter-white: brightness(0) invert(1);
$color-filter-black: brightness(0%);
$color-filter-gray-400: invert(70%) sepia(0%) saturate(1%) hue-rotate(356deg)
  brightness(95%) contrast(85%);
$color-filter-gray-600: brightness(0) saturate(100%) invert(63%) sepia(0%)
  saturate(34%) hue-rotate(192deg) brightness(98%) contrast(88%);
$color-filter-orange-500: brightness(0) saturate(100%) invert(61%) sepia(57%)
  saturate(717%) hue-rotate(333deg) brightness(99%) contrast(92%);

// Typography
$heading-font: Nunito, sans-serif;
$heading-font-color: color(gray, 700);

$body-font: "Open Sans", sans-serif;
$button-font-semibold: "Nunito Semibold";
$body-font-color: $black;
$body-line-height: 1.5;

$margin-small: 8px;
$margin-default: 16px;
$margin-large: 32px;

$padding-small: 8px;
$padding-default: 16px;
$padding-large: 32px;

$border-radius-default: 2px;

$box-shadow-default: 0 2px 4px 0 rgba(0, 0, 0, 0.25);

$form-element-height: 3rem;

$sidebar-width: 50px;
$container-width: 1440px;
$panel-width: 510px;
$text-content-width: 680px;
$ipad-breakpoint: 1024px;
$breakpoint-md: 768px;
$phone-portrait-breakpoint: 320px;

$calendar-suggested-post-color: #eecfb8;
$calendar-post-facebook-color: #b7c7e8;
$calendar-post-google-color: #ecffe4;
$calendar-post-linkedin-color: #e4c5ff;

$brand-main-container-padding: 24px;
$partners-invite-expiration-banner-height: 56px;

$header-menu-item-height: 44px;

:root {
  --theme-color-primary: #f28a3d;
  --gradient-color-high: #fd8040;
  --gradient-color-low: #fda143;
  --header-height: 64px;
  --page-header-height: 80px;
  --footer-height: 124px;
  --footer-background-color: #{color(gray, 900)};
  --footer-padding: 50px;
}
