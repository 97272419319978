.sidebar {
  background: color(gray, 700);
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;
  left: 0;
  width: $sidebar-width;
  overflow: hidden;
  -webkit-transition: width 0.25s ease-out;
  transition: width 0.25s ease-out;
  z-index: 999;

  &--white-label {
    background: var(--gradient-color-high);
  }
}
