/*
 * Forms
 */

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
  min-height: 0;
}

legend {
  padding: 0;
  display: table;
}

.show-password {
  position: relative;
  input {
    padding-right: 120px;
  }
  .show-password-trigger {
    position: absolute;
    top: 8px;
    right: 0;
    cursor: pointer;
  }
}

.drag-and-drop-label {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  min-height: 121px;
  padding: 50px 70px;
  background-color: var(--theme-color-primary);
  border: 1px dashed $color-white;

  &:hover,
  &:focus,
  &.nv-file-over {
    background-color: color(orange, 600);
  }

  &.image .drag-and-drop-label__img {
    width: 60px;
    height: 60px;
  }

  &.document .drag-and-drop-label__document {
    background-image: url("/assets/images/icons/ic_Documents.svg");
  }

  &__img {
    width: 48px;
    height: 48px;
    margin-right: $margin-default;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  &__text {
    display: flex;
    flex-direction: column;
    color: white;
    font-family: "Open Sans";

    &__title {
      font-size: 1.125rem;
      line-height: 24px;
      margin-bottom: 4px;
    }

    &__subtitle {
      font-size: 0.875rem;
      line-height: 19px;
    }
  }
}
