@import "core";
/*
 * General styles
 */
*,
*:before,
*:after {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}

html {
  overflow-x: hidden;
}

body {
  min-height: 100%;
  margin: 0;

  &.prevent-scrolling {
    overflow: hidden;
  }
}

[hidden] {
  display: none !important;
}

p {
  line-height: 22px;
}

.loader-wrapper {
  position: relative;
  min-height: 400px;
  max-height: 100%;
}

mat-icon {
  vertical-align: text-bottom;
  display: inline-block;
}

@media only screen and (max-width: $ipad-breakpoint) {
  .hide-for-small {
    display: none !important;
  }
}
@media only screen and (min-width: $ipad-breakpoint) {
  .show-for-small-only {
    display: none !important;
  }
}

.icon-in-text {
  font-size: 1.2em !important;
  vertical-align: middle;
}

section.save {
  width: 100%;
  margin-bottom: 24px;
  display: block;
  overflow: hidden;
  margin-top: 24px;
  .button-orange.save {
    float: right;
    min-width: 120px;
  }
  &.w700 {
    max-width: 700px;
  }
  &.w920 {
    max-width: 920px;
  }
}

.arrow-down {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 6px solid white;

  &.--dark {
    border-top: 6px solid black;
  }
}

span.highlighted {
  color: var(--theme-color-primary);
}

.chip {
  padding: 4px 8px;
  background: color(gray, 100);
  color: color(gray, 500);
  border-radius: 4px;
  font-size: 14px;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;

  .bold {
    color: color(gray, 900);
    font-weight: 600;
  }

  &.warning {
    background-color: color(red, 500);
    color: white;
  }
}

.hidden {
  display: none !important;
}
