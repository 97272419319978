@import "core";

.cal-month-view {
  .cal-header {
    border-left: 1px solid color(gray, 200);
    border-right: 1px solid color(gray, 200);

    .cal-cell {
      color: color(gray, 700);
      font-family: Nunito, serif;
      font-size: 14px;
      font-weight: bold;
      text-transform: uppercase;
    }
  }

  .cal-out-month {
    .cal-day-badge,
    .cal-event {
      opacity: 1;
    }
    &.cal-day-cell .cal-day-number {
      opacity: 1;
      cursor: initial;
    }
  }

  .cal-days .cal-cell-row .cal-cell {
    flex: initial;
  }

  .cal-cell-top {
    display: flex;
    flex: initial;
    min-height: 0;
  }

  .cal-day-outbound-icon {
    font-size: 14px;
    float: left;
    padding-left: 10px;
    padding-top: 5px;
  }

  .cal-day-cell--outbound {
    background-image: url(/assets/images/as_tile_blocked_gray.png);
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  .cal-day-cell {
    font-size: 16px;
    width: 14.25%;
    min-height: 140px;

    .cal-day-number {
      font-size: 1em;
      margin-top: 10px;
      margin-right: 0;
      margin-bottom: 16px;
      opacity: 1;
      width: 100%;
      text-align: right;
      padding-right: 10px;
    }

    &.cal-today {
      color: darken(color(orange, 500), 10%);
      background-color: lighten(color(orange, 500), 37%);

      .cal-day-number {
        font-weight: bold;
        font-size: 1em;
      }
    }

    &.cal-weekend {
      .cal-day-number {
        color: #e45c6d;
      }
    }

    &.cal-in-month.cal-has-events {
      cursor: initial;
    }
  }

  .cal-events {
    flex: initial;
    display: flex;
    gap: 6px;
    margin: 0;
  }

  .cal-event--brand {
    background-color: #ecffe4;
    border-radius: 0;
  }
  .cal-event--partner {
    background-color: transparent;
    border-radius: 5px;
  }
  .cal-event--partner--google {
    border: 1px solid #34a852;
  }
  .cal-event--partner--facebook {
    border: 1px solid #4267b2;
  }
  .cal-event--partner--linkedin {
    border: 1px solid $calendar-post-linkedin-color;
  }
  .cal-event--brand--suggested-post {
    background-color: $calendar-suggested-post-color;
    border: 1px solid $calendar-suggested-post-color;
  }
  .cal-event--brand--post-log--facebook {
    background-color: $calendar-post-facebook-color;
  }
  .cal-event--brand--post-log--google {
    background-color: $calendar-post-google-color;
  }
  .cal-event--brand--post-log--linkedin {
    background-color: $calendar-post-linkedin-color;
  }
  .cal-event {
    position: relative;
    overflow: hidden;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
    color: color(gray, 700);
    font-size: 12px;
    height: 70px;
    padding-left: 5px;
    width: 96%;
    margin-left: 2%;
    margin-right: 2%;
    margin-bottom: 26px;

    .event-icon {
      display: flex;
      flex-direction: row;
      margin-top: 5px;
      margin-left: 5px;
      width: 35px;

      img {
        margin-right: 5px;
      }
    }

    .dark-post {
      display: flex;
      flex-direction: row;
      padding-top: 0 !important;

      img {
        margin-right: 2px;
      }
    }
    .event-content {
      font-family:
        Nunito Bold,
        serif !important;
      padding-left: 5px;
      padding-top: 0;

      .promo-ads-text {
        color: var(--theme-color-primary);
        font-size: 9px;
        line-height: 12px;
      }

      .event-text {
        font-size: 12px;
        line-height: 16px;
      }

      .event-text,
      .promo-ads-text {
        padding-top: 2px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 400px;
      }
    }
    &.cal-event--partner--facebook {
      margin: 2px 2px 5px;

      &.cal-event--isDarkPost {
        margin-bottom: 16px;
      }

      &.cal-event--hasPromoAds {
        margin-bottom: 2px;
      }

      &.cal-event--isDarkPost.cal-event--hasPromoAds {
        margin-bottom: 2px;
      }
    }
    &.cal-event--partner--google {
      margin-bottom: 28px;

      &.cal-event--isDarkPost {
        margin-bottom: 18px;
      }

      &.cal-event--hasPromoAds {
        margin-bottom: 4px;
      }

      &.cal-event--isDarkPost.cal-event--hasPromoAds {
        margin-bottom: 4px;
      }
    }

    &.cal-event--partner--linkedin {
      margin-bottom: 26px;

      .event-icon {
        margin-top: 2px;
      }
    }

    &.is-first-day {
      border-right: none;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      margin-right: 0;
    }
    &.is-center-day {
      border-left: none;
      border-right: none;
      border-radius: 0;
      box-shadow: none;
      background-color: white;
      margin-left: -1px;
      margin-right: -1px;
      width: calc(100% + 4px);
    }
    &.is-last-day {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left: none;
      margin-left: -1px;
      width: calc(100% + 2px);
      box-shadow: none;
      background-color: white;
    }
    &.is-being-dragged {
      opacity: 0;
      height: 1px;
    }
    .loading-icon {
      display: none;
      text-shadow: none;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 30px;
      height: 30px;
      font-size: 30px;
      color: var(--theme-color-primary);
      margin-left: 0;
      animation: infiteRotation 1s infinite linear;
      transform-origin: 0% 0%;
    }
    @keyframes infiteRotation {
      from {
        transform: rotate(0deg) translate(-50%, -50%);
      }
      to {
        transform: rotate(359deg) translate(-50%, -50%);
      }
    }

    &.collapsed {
      width: 16px;
      height: 16px;
      margin: 0;

      .event-image,
      .event-content,
      .loading-icon {
        display: none;
      }
    }

    &.is-approved {
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2);
    }

    &.is-not-approved {
      background-color: transparent;
      opacity: 0.3;
      .event-content {
        .event-text {
          // color: color(gray, 700);
        }
      }
      &.calendar-approved {
        // opacity: 0.3;
      }
    }

    &.loading {
      background-color: color(gray, 200);
      .event-image {
        opacity: 0.5;
      }
      .loading-icon {
        display: block;
      }
    }
  }
}

.calendar-header {
  .legend {
    li {
      /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
      .mat-slide-toggle-content {
        padding-top: 2px;
      }
      &.your-posts {
        /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
        .mat-mdc-slide-toggle.mat-checked:not(.mat-disabled)
          .mat-slide-toggle-thumb {
          background-color: #acf88c;
        }
        /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
        .mat-mdc-slide-toggle.mat-checked:not(.mat-disabled)
          .mat-slide-toggle-bar {
          background-color: #ceeec0;
        }
      }
      &.suggested-posts {
        /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
        .mat-mdc-slide-toggle.mat-checked:not(.mat-disabled)
          .mat-slide-toggle-thumb {
          background-color: var(--theme-color-primary);
        }
        /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
        .mat-mdc-slide-toggle.mat-checked:not(.mat-disabled)
          .mat-slide-toggle-bar {
          background-color: #eecfb8;
        }
      }
    }
  }
}

.cal-tooltip {
  .cal-tooltip-inner {
    line-height: 20px;
    .mat-icon {
      font-size: 15px;
      height: 15px;
      width: 15px;
    }
    .uppercase {
      text-transform: uppercase;
    }
  }
}

.sp-day {
  .add {
    border-radius: 4px;
    height: 28px;
    width: 28px;
    background-color: orange;
    font-size: 18px;
    border: none;
    color: white;
    display: none;
    cursor: pointer;
  }

  .fc-daygrid-day-number {
    width: 100%;
  }

  .add-button {
    cursor: pointer;
  }

  &--inside-range {
    &:hover {
      .add-button {
        display: block;
      }
    }
  }

  .add-button {
    background-color: var(--theme-color-primary);
    border: none;
    color: white;
    border-radius: 4px;
    width: 32px;
    height: 32px;
    font-size: 20px;
  }
}

.fc-event {
  border: none;
}

.fc .fc-day-other .fc-daygrid-day-top {
  opacity: 1;
}

.fc-prev-button.fc-button-primary,
.fc-next-button.fc-button-primary {
  background: none !important;
  border: none !important;
  color: black !important;
  padding: 4px !important;
  height: 28px !important;
  display: inline-flex;
  align-items: center;

  .fc-icon {
    font-size: 20px !important;
  }

  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }

  &:disabled {
    opacity: 0.5;
  }
}

.fc-today-button.fc-button-primary {
  background: none !important;
  border: 1px solid color(gray, 300) !important;
  color: color(gray, 800) !important;
  height: 28px !important;
  font-size: 12px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 14px;
  font-weight: bold;

  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
}

.fc-toolbar-title {
  display: inline-flex;
  font-size: 18px !important;
  font-weight: bold;
  color: color(gray, 800) !important;
}

.fc-event {
  margin-bottom: 2px;
}
