@use "sass:math";

$grid-gap: 24px;
$grid-gap-half: math.div($grid-gap, 2);

.container {
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
}

.row {
  display: flex;
  margin-left: -$grid-gap-half;
  margin-right: -$grid-gap-half;
  flex-wrap: wrap;
  overflow-x: clip;

  &.direction-down {
    flex-direction: column;
  }

  &.no-wrap {
    flex-wrap: nowrap;
  }

  &.align-center {
    justify-content: center;
  }

  &.valign-center {
    align-items: center;
  }
}

.col {
  padding-left: $grid-gap-half;
  padding-right: $grid-gap-half;
  width: 100%;

  @media (min-width: $ipad-breakpoint + 1) {
    // Can be specified or adjusted later on
    &.width-auto {
      width: auto;
    }

    &.width-100 {
      width: 100%;
    }

    &.width-80 {
      width: 80%;
    }

    &.width-75 {
      width: 75%;
    }

    &.width-70 {
      width: 70%;
    }

    &.width-60 {
      width: 60%;
    }

    &.width-55 {
      width: 55%;
    }

    &.width-50 {
      width: 50%;
    }

    &.width-45 {
      width: 45%;
    }

    &.width-40 {
      width: 40%;
    }

    &.width-30 {
      width: 30%;
    }

    &.width-25 {
      width: 25%;
    }

    &.width-20 {
      width: 20%;
    }
  }
}
