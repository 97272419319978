@import "core";

:root {
  --sp-btn-size: 40px;
}

.btn {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 600;

  display: flex;
  margin: 0; /* Remove the margin in Firefox and Safari. */
  color: color(gray, 700);
  border: 0;
  height: var(--sp-btn-size);
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  text-transform: none; /* Remove the inheritance of text transform in Firefox. */
  user-select: none;
  white-space: nowrap;
  cursor: pointer;
  overflow: visible; /* Show the overflow in Edge. */
  /* required for styling <a> as button */
  // line-height: 3; /* equal to form element height */
  text-decoration: none;
  min-width: 120px;
  transition: 200ms;

  gap: 8px;

  > * {
    transition: 200ms;
  }

  &::-moz-focus-inner,
  &::-moz-focus-inner {
    /* Remove the inner border and padding in Firefox. */
    border-style: none;
    padding: 0;
  }

  &:active,
  &:active {
    background-image: none;
  }

  &.is-disabled, /* for anchors styled as buttons */
  &:disabled,
  &[disabled],
  &.is-disabled, /* for anchors styled as buttons */
  &:disabled,
  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &.button-full-width {
    width: 100%;
  }

  &:focus {
    outline: none;
  }

  &--content-only {
    background-color: transparent;
    border: none;
    padding: 0;
    height: fit-content;
    min-width: 0;
  }

  &--grey {
    background-color: color(gray, 700);
    color: white;

    /* for <a> styled as button */
    &:visited {
      background-color: color(gray, 700);
      color: white;
    }

    &:hover,
    &:focus {
      background-color: color(gray, 800);
      color: white;
    }
  }

  &--bluegray {
    background-color: color(bluegray, 800);
    color: white;

    /* for <a> styled as button */
    &:visited {
      background-color: color(bluegray, 700);
      color: white;
    }

    &:hover,
    &:focus {
      background-color: color(bluegray, 900);
      color: white;
    }
  }

  &--transparent {
    background-color: transparent;
    color: var(--theme-color-primary);

    &:hover,
    &:focus {
      background-color: transparent;
      color: color(orange, 600);
    }

    &:hover {
      background-color: color(gray, 100);
    }
  }

  &--link {
    border: none;
    background: none;
    width: fit-content;
    color: var(--theme-color-primary);
    font-size: inherit;
    height: auto;
    font-weight: bold;

    &:hover {
      text-decoration: underline;
    }
  }

  &--border-only {
    background-color: transparent;
    color: color(orange, 500);
    border: 1px solid color(gray, 200);
    padding: 8px 16px;
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;

    mat-icon {
      color: color(orange, 500);
    }

    &:hover,
    &:focus {
      background-color: color(gray, 100);
    }
  }

  &--primary {
    background-color: var(--theme-color-primary);
    color: white;

    /* for <a> styled as button */
    &:visited {
      background-color: var(--theme-color-primary);
      color: white;
    }

    &:hover {
      background-color: color(orange, 600);
      color: white;
    }
  }

  &--dark {
    &,
    &:visited {
      // TODO: Pablo needs to define this palette
      background-color: #3d405b;
      color: white;
    }

    &:hover,
    &:focus {
      // TODO: Pablo needs to define this palette
      background-color: #474a69;
    }
  }

  &--light-grey {
    background-color: color(gray, 200);
    color: var(--theme-color-primary);

    /* for <a> styled as button */
    &:visited {
      background-color: color(gray, 200);
      color: var(--theme-color-primary);
    }

    &:hover,
    &:focus {
      background-color: color(gray, 200);
      color: var(--theme-color-primary);
    }
  }

  &--delete {
    color: color(red, 600);
  }

  &__icon {
    margin: 0 0 0 6px;
  }

  &--image-only {
    background: none;
    border: none;
    min-width: 0;
  }

  &--full-width {
    width: 100%;
  }

  &--square {
    @include flex-centered-content;

    width: var(--sp-btn-size);
    min-width: 0;
    padding: 0;

    mat-icon {
      margin: 0;
    }
  }
}
