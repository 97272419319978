@import "core";

/*
 * Buttons
 */
.round-button {
  display: inline-block; /* required for styling <a> as button */
  margin: 0; /* Remove the margin in Firefox and Safari. */
  border: 0px;
  padding: 12px;
  padding-right: 50px;
  padding-left: 50px;

  border-radius: 4px;
  font-family: "Open sans", sans-serif;
  font-size: 1rem;
  font-weight: 300;
  text-transform: none; /* Remove the inheritance of text transform in Firefox. */
  text-align: center;
  user-select: none;
  color: white;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  overflow: visible; /* Show the overflow in Edge. */
  /* required for styling <a> as button */
  text-decoration: none;

  &--small {
    font-size: 14px;
    padding: 10px;
    height: 40px;
    line-height: 1;
  }

  &::-moz-focus-inner,
  &::-moz-focus-inner {
    /* Remove the inner border and padding in Firefox. */
    border-style: none;
    padding: 0;
  }

  &:active,
  &:active {
    background-image: none;
  }

  &.is-disabled, /* for anchors styled as buttons */
  &:disabled,
  &[disabled],
  &.is-disabled, /* for anchors styled as buttons */
  &:disabled,
  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &.button-full-width {
    width: 100%;
  }

  &:focus {
    outline: none;
  }

  &--grey {
    background-color: color(gray, 700);
    color: white;

    /* for <a> styled as button */
    &:visited {
      background-color: color(gray, 700);
      color: white;
    }

    &:hover,
    &:focus {
      background-color: color(gray, 800);
      color: white;
    }
  }

  &--orange {
    background-color: var(--theme-color-primary);
    color: white;

    /* for <a> styled as button */
    &:visited {
      background-color: var(--theme-color-primary);
      color: white;
    }

    &:hover,
    &:focus {
      background-color: color(orange, 600);
      color: white;
    }
  }

  &--light-grey {
    background-color: color(gray, 200);
    color: var(--theme-color-primary);

    /* for <a> styled as button */
    &:visited {
      background-color: color(gray, 200);
      color: var(--theme-color-primary);
    }

    &:hover,
    &:focus {
      background-color: color(gray, 200);
      color: var(--theme-color-primary);
    }
  }
}

.button-orange,
.button-white,
.button-transparent,
.button-grey,
.button-red,
.button-light-grey,
.button-blue {
  display: inline-block; /* required for styling <a> as button */
  height: 3rem;
  margin: 0; /* Remove the margin in Firefox and Safari. */
  padding: 0 1em;
  border: 1px solid transparent;
  border-radius: 4px;
  font-family: "Open sans", sans-serif;
  font-size: 1rem;
  font-weight: normal;
  text-transform: none; /* Remove the inheritance of text transform in Firefox. */
  text-align: center;
  user-select: none;
  color: white;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  overflow: visible; /* Show the overflow in Edge. */
  /* required for styling <a> as button */
  text-decoration: none;

  &--small {
    font-size: 14px;
    padding: 10px;
    height: 40px;
    line-height: 1;
  }

  &::-moz-focus-inner,
  &::-moz-focus-inner {
    /* Remove the inner border and padding in Firefox. */
    border-style: none;
    padding: 0;
  }

  &:active,
  &:active {
    background-image: none;
  }

  &.is-disabled, /* for anchors styled as buttons */
  &:disabled,
  &[disabled],
  &.is-disabled, /* for anchors styled as buttons */
  &:disabled,
  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &.button-full-width {
    width: 100%;
  }

  &:focus {
    outline: none;
  }
}

button.button-orange
  button.button-white
  button.red
  button.button-transparent
  button.button-grey
  button.button-light-grey {
  -webkit-appearance: button; /* Correct the inability to style clickable types in iOS and Safari. */
}

.button-orange {
  background-color: var(--theme-color-primary);
  color: white;

  /* for <a> styled as button */
  &:visited {
    background-color: var(--theme-color-primary);
    color: white;
  }

  &:hover,
  &:focus {
    background-color: var(--theme-color-primary);
    color: white;
  }
}

.button-white {
  background-color: $color-white;
  color: var(--theme-color-primary);

  /* for <a> styled as button */
  &:visited {
    background-color: $color-white;
    color: var(--theme-color-primary);
  }

  &:hover,
  &:focus {
    background-color: darken($color-white, 10%);
    color: var(--theme-color-primary);
  }
}

.button-transparent {
  border-color: color(gray, 200);
  background-color: transparent;
  color: color(gray, 500);

  /* for <a> styled as button */
  &:visited {
    background-color: transparent;
    color: color(gray, 500);
  }

  &:hover,
  &:focus {
    background-color: transparent;
    color: $color-black;
  }
}

.button-grey {
  background-color: color(gray, 700);
  color: white;

  /* for <a> styled as button */
  &:visited {
    background-color: color(gray, 700);
    color: white;
  }

  &:hover,
  &:focus {
    background-color: color(gray, 800);
    color: white;
  }
}

.button-red {
  background-color: color(red, 600);
  color: white;

  /* for <a> styled as button */
  &:visited {
    background-color: color(red, 600);
    color: white;
  }

  &:hover,
  &:focus {
    background-color: color(red, 600);
    color: white;
  }
}

.button-light-grey {
  background-color: color(gray, 200);
  color: var(--theme-color-primary);

  /* for <a> styled as button */
  &:visited {
    background-color: color(gray, 200);
    color: var(--theme-color-primary);
  }

  &:hover,
  &:focus {
    background-color: color(gray, 200);
    color: var(--theme-color-primary);
  }
}

.button-blue {
  background-color: $blue;
  color: white;

  /* for <a> styled as button */
  &:visited {
    background-color: $blue;
    color: white;
  }

  &:hover,
  &:focus {
    background-color: darken($blue, 10%);
    color: white;
  }
}

.button-link {
  display: inline;
  padding: 0;
  font-family: inherit;
  font-size: inherit;
  border: none;
  background: none;
  color: var(--theme-color-primary);
  cursor: pointer;

  &:hover {
    color: color(orange, 600);
  }

  &:focus {
    outline: none;
  }
}

/*
 * Anchors
 */

a {
  color: var(--theme-color-primary);
  text-decoration: none;

  &:visited {
    color: var(--theme-color-primary);
  }

  &:hover {
    color: color(orange, 600);
  }

  &.link-grey {
    color: color(gray, 700);

    &:hover {
      color: color(gray, 800);
    }
  }

  &.dark {
    color: color(gray, 700);

    &:hover {
      color: color(orange, 500);
    }
  }
}

div.dark a {
  color: color(gray, 700);

  &:hover {
    color: color(orange, 500);
  }
}
