@import "variables";

.block {
  color: color(gray, 700);
  font-size: 16px;

  &__section {
    margin-bottom: 32px;

    &--disabled {
      .block__title,
      .block__subtitle {
        opacity: 70%;
      }

      .checkbox--icon + label {
        opacity: 40%;
        cursor: default;
      }
    }
  }

  &__description {
    margin: 0;
  }

  &__title {
    display: flex;
    font-weight: bold;
    margin: 0 0 8px;
    justify-content: space-between;

    &__tooltip {
      float: right;
      cursor: pointer;
    }
  }

  &__toggle {
    display: flex;
    justify-content: space-between;
  }

  &__subtitle {
    margin-bottom: 18px;
  }

  &__field {
    width: 100%;

    &--toggle {
      display: inline-flex;
      justify-content: space-between;
      font-size: 14px;
      margin-bottom: 16px;
    }
  }

  &__subfield {
    padding-left: 29px;
  }

  &__radio-button {
    margin-bottom: 24px;
  }

  &__icon {
    &--clickable {
      cursor: pointer;
    }
  }

  &__uploader {
    margin: 8px 0 24px;
    position: relative;
  }

  .image-viewer {
    display: flex;
    margin-top: 20px;

    &__image {
      width: 200px;
      height: auto;
    }

    &__delete {
      margin: -10px 0 0 -10px;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background: color(orange, 500);
      color: $color-white;
      font-size: 14px;
      border: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }

  .logo-preview {
    width: 100%;
    height: 100px;

    display: flex;
    justify-content: center;
    align-items: center;

    &__logo {
      height: 100px;
      width: auto;
    }
  }

  .checkbox {
    &--icon {
      display: none;

      & + label {
        height: 40px;
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        color: black;
        cursor: pointer;

        .mat-icon {
          margin: 0;
        }
      }

      &:checked + label {
        background-color: var(--theme-color-primary);
        color: white;
      }
    }
  }

  .vertical-separator {
    display: flex;
    width: 1px;
    background-color: color(gray, 200);
  }
}

.disable-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: white;
  opacity: 0.7;
  z-index: 999;
}

.lp-preview {
  &__button {
    border-radius: 6px;
    background-color: color(orange, 500);
    color: $black;
    font-family: $body-font;
    font-size: 18px;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;

    &--roboto-slab {
      border-radius: 28px;
    }

    &--merriweather {
      border-radius: 0;
      border: 1px solid $black;
      box-sizing: border-box;
      box-shadow: 4px 4px 0 0 $black;
    }
  }
}
