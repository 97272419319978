.sidebar-icon {
  display: block;
  width: 24px;
  height: 24px;
  margin: 0;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;

  &--brand-partners {
    background-image: url(/assets/images/icons/brand_partner.svg);
  }

  &--dashboard {
    background-image: url(/assets/images/icons/ic_dashboard_hover.svg);
  }

  &--help {
    background-image: url(/assets/images/icons/ic_sdb_bulb_icon.svg);
  }

  &--home {
    background-image: url(/assets/images/icons/brand-campaigns.svg);
  }

  &--settings {
    background-image: url(/assets/images/icons/ic_sdb_settings_icon.svg);
  }

  &--incidents {
    background-image: url(/assets/images/icons/ic_alarm_light.svg);
  }

  &--ads {
    background-color: white;
    mask-image: url(/assets/images/icons/ads_click.svg);
  }
}
