// TODO: These styles should eventually be standarised
.mo-mat-table {
  --mo-mat-table-cell-padding: 24px;

  $border-color: color(gray, 100);

  display: table;
  border-style: solid !important;
  border-color: $border-color !important;
  border-width: 1px !important;

  &--full-width {
    width: 100%;
  }

  th.mat-mdc-header-cell {
    border-bottom: 1px solid $border-color;

    &,
    &:first-of-type,
    &:last-of-type {
      padding: 0;
    }
  }

  .mdc-data-table__cell {
    overflow: initial;
  }

  td.mat-mdc-cell {
    border-bottom: 1px solid $border-color;
    padding: var(--mo-mat-table-cell-padding);
  }

  .table-header {
    &__column,
    &__filter {
      display: flex;
      align-items: center;
      padding: 0 var(--mo-mat-table-cell-padding);
    }

    &__column {
      height: 40px;
      background-color: $color-black;
      font-family: $heading-font;
      font-size: 14px;
      font-weight: bold;
      color: $color-white;
    }

    &__column-name {
      @include text-ellipsis(1);
      line-height: 1.3;
    }

    &__filter {
      height: 64px;
      background-color: color(gray, 100);

      mat-form-field {
        width: 100%;
      }
    }
  }
}

.table-empty-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: 64px 32px;
  text-align: center;
  border: 1px solid color(gray, 100);
  border-top: 0;
}
