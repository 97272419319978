@use "sass:math";

.meta {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin: 0px;
  padding: 0px;

  &__item {
    $line-height: 18px;

    line-height: $line-height;
    display: flex;
    align-items: flex-start;
    list-style: none;
    color: color(gray, 800);
    font-family: $body-font;
    font-size: 12px;

    mat-icon {
      $height: 16px;

      flex-shrink: 0;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 16px;
      height: $height;
      font-size: 16px;
      color: color(gray, 400);
      margin-right: 8px;
      margin-top: math.div($line-height - $height, 2);
    }

    img {
      $height: 14px;

      flex-shrink: 0;
      width: 16px;
      height: $height;
      margin-right: 8px;
      margin-top: math.div($line-height - $height, 2);
      filter: $color-filter-gray-400;
    }

    &--active {
      mat-icon {
        color: color(orange, 500);
      }

      img {
        filter: $color-filter-orange-500;
      }
    }
  }
}
