// Core
@import "core";

// Import core styles
@import "utilities";
@import "buttons-links-old";
@import "button";
@import "forms";
@import "general";
@import "typography";
@import "grid";
@import "text";
@import "toast";
@import "placeholder";

// Shared Styles
@import "landing-page";
@import "meta";
@import "sidebar";
@import "sidebar-icon";
@import "sidebar-menu";

// Import Angular 2 Material Design Theme
// More info: https://material.angular.io/guide/theming
@import "material-design-theme";

@import "../../node_modules/angular-calendar/css/angular-calendar.css";
@import "calendar-overrides";
@import "dialog-overrides";
@import "styles-overrides";

// Import Angular 2 Material Design overrides
@import "material-design-overrides";
@import "mo-mat-table";

@import "ckeditor5/ckeditor5.css";
