@import "core";

$white-field-text-color: $color-white;
$white-field-border-color: rgba(255, 255, 255, 0.6);
$white-field-disabled: rgba(255, 255, 255, 0.4);

/*
 *  In Angular 2, styles are scoped to each component. It's not possible to
 *  change the styles of a component from another component.
 *
 *  To get around this, we load the overrides in a stylesheet separate
 *  from the Angular app.
 *
 *  More about style encapsulation: https://angular.io/guide/component-styles
 */

mat-icon {
  &.mirror-y {
    transform: scaleY(-1);
  }
}

.mat-mdc-checkbox
  .mdc-checkbox
  .mdc-checkbox__native-control:enabled
  ~ .mdc-checkbox__background
  .mdc-checkbox__checkmark {
  color: white !important;
}

.mat-mdc-checkbox.white {
  .mdc-form-field {
    color: $white-field-text-color !important;
  }

  .mdc-checkbox .mdc-checkbox__background {
    border-color: $white-field-text-color !important;
  }

  &.mat-mdc-checkbox-checked .mdc-checkbox__background {
    border-color: transparent !important;
  }

  &.mat-mdc-checkbox-disabled {
    label {
      color: $white-field-disabled !important;
    }
    .mdc-checkbox .mdc-checkbox__background {
      border-color: $white-field-disabled !important;
    }
  }
}

// Align radio button circle to the top
.mat-mdc-radio-button {
  // Radio buttons have 20px diameter by default, and Material defines
  // `--mdc-radio-state-layer-size` of 32px by default. The problem is that this
  // variable is scoped to `.mdc-radio`, that's why we use this override variable
  --mo-mdc-radio-state-layer-size: 32px;

  .mdc-radio {
    // Override the CSS variable to ensure consistency
    --mdc-radio-state-layer-size: var(--mo-mdc-radio-state-layer-size);
    align-self: flex-start;
  }

  label {
    // Find the middle point, and offset by half text-height, this will work as
    // long as the line-height is `1`
    align-self: flex-start;
    font-family: "Open Sans", sans-serif;

    > mat-icon {
      font-size: 20px;
      width: 24px;
      height: 20px;
    }
  }

  &.mo-mat-radio-button--extra-margin {
    label {
      margin-top: 6px;
    }
  }
}

.mat-mdc-form-field-icon-suffix {
  // Add extra trailing space
  padding-right: 8px !important;

  // Fix icon centering for input suffix icon-button
  .mdc-icon-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0;

    .mat-icon {
      margin: 0;
    }
  }

  mat-icon {
    color: color(gray, 700) !important;
    padding: 4px !important;
  }

  svg {
    color: color(gray, 700) !important;
  }
}

// Remove extra space at beginning of hints/errors so they align with input content/placeholder/label
.mat-mdc-form-field {
  .mat-mdc-form-field-hint,
  .mat-mdc-form-field-error {
    &::before {
      display: none;
    }
  }
}

.mat-mdc-form-field-icon-prefix .mat-prefix--text {
  padding: 0 0 0 16px !important;
}

.mat-mdc-form-field.no-hint {
  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
}

// Full width fields
.mat-mdc-form-field.mo-auto-width {
  width: 100%;

  .mat-mdc-form-field-infix {
    width: auto;
  }
}

// Add background color to "search field" inside select fields
.mat-select-search-inner-row {
  background-color: $color-white;
}

.mat-mdc-progress-bar.mo-progress-bar-thick {
  --mdc-linear-progress-track-height: 30px;
  --mdc-linear-progress-active-indicator-height: 30px;
}

// For style retrocompatibility
.mat-mdc-tab {
  min-width: 160px !important;

  &.mat-mdc-tab.mdc-tab--active .mdc-tab__text-label {
    color: #0a0a0a !important;
  }
}

.mat-mdc-tab-group.mo-compact-tabs {
  --mat-tab-header-active-label-text-color: color(gray, 700);
  --mat-tab-header-inactive-label-text-color: color(gray, 700);

  .mat-mdc-tab-header {
    --mat-tab-header-label-text-size: 16px;
    --mat-tab-header-label-text-weight: 400;
  }

  .mat-mdc-tab-label-container {
    border-bottom: 1px solid color(gray, 300);
  }

  .mat-mdc-tab {
    min-width: 0 !important;
    flex-grow: 0 !important;
    padding: 0 16px;
  }
}

.mat-mdc-form-field.white-field {
  .mdc-text-field__input {
    color: $white-field-text-color !important;
  }
  .mat-mdc-select-value {
    color: $white-field-text-color !important;
  }
  label {
    color: $white-field-border-color !important;
  }
  .mdc-notched-outline > div {
    border-color: $white-field-border-color !important;
  }
  .mat-mdc-select-arrow {
    color: $white-field-border-color !important;
  }
  .mat-mdc-form-field-icon-suffix svg {
    color: $white-field-border-color !important;
  }
}

.mat-mdc-slider {
  &.slider-thumb {
    .mdc-slider__value-indicator {
      transform: scale(1) rotate(45deg) translateX(-7px) translateY(7px) !important;
    }
  }

  &.mdc-slider--disabled {
    .mdc-slider__value-indicator {
      background-color: color(gray, 500) !important;
    }
  }

  .mdc-slider__thumb--with-indicator {
    .mdc-slider__value-indicator {
      transform: scale(1) rotate(45deg) translateX(-7px) translateY(7px) !important;
    }
  }

  .mdc-slider__value-indicator-container {
    padding: 0;

    .mdc-slider__value-indicator {
      display: flex;
      justify-content: center;
      border-radius: 50% 50% 0 !important;
      width: 28px;
      height: 28px;
      background-color: #ffab40;
      opacity: 1;

      &::before {
        display: none;
      }

      .mdc-slider__value-indicator-text {
        font-size: 12px;
        color: white;
        transform: rotate(-45deg);
      }
    }
  }
}

mat-form-field {
  textarea {
    resize: none !important;
    font-size: 14px !important;
  }

  mat-hint {
    font-size: 12px !important;
    color: color(gray, 500);
  }
}

.mdc-data-table__content {
  tr:hover {
    background-color: color(gray, 50);
  }
}

.placeholder {
  padding: 1px 6px;
  border: 1px solid color(gray, 300);
  background-color: transparent;
  border-radius: 2px;
  font-weight: 600;
  font-size: 13px;
}

.editor-footer .ck-button {
  padding: 4px;
  border-radius: 4px;
  border: 1px solid #ccc;
  cursor: pointer;
}

.ck-rounded-corners .ck.ck-editor__main > .ck-editor__editable,
.ck.ck-editor__main > .ck-editor__editable.ck-rounded-corners {
  min-height: 160px;
  padding-top: 12px;
  padding-bottom: 12px;

  > p {
    margin: 0;
  }
}

app-brand-campaign-promo-ads {
  .mat-mdc-radio-button .mat-internal-form-field {
    display: inline-flex;
    align-items: center;
  }

  .mat-mdc-radio-button label {
    align-self: center;
  }
}
