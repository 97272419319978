.sidebar-menu {
  list-style: none;
  margin: 0;
  padding-left: 0;
  position: sticky;
  top: 0;
  height: 100%;

  &__item {
    position: relative;
    display: block;
    cursor: pointer;
  }

  &__link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: $sidebar-width;
    height: $sidebar-width;
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: bold;
    line-height: 19px;
    text-decoration: none;
    position: relative;

    &,
    &:visited,
    mat-icon {
      color: $color-white;
    }

    &:hover {
      background-color: rgba($color-white, 0.1);
    }

    img {
      float: left;
      margin-top: 2px;
      width: 18px;
      height: 18px;
    }

    &--active {
      background-color: var(--theme-color-primary) !important;
    }
  }
}
