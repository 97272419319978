@import "variables";

// To leave space when dialogs are too high
.cdk-global-overlay-wrapper {
  padding: 16px 0;
}

/* TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version.*/
.mat-mdc-dialog-container {
  padding: 0 !important;
  border-radius: 0 !important;
  color: rgba(0, 0, 0, 0.87) !important;
  position: relative;

  .close-icon {
    font-size: 24px;
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
    color: black;
    width: 24px;
    height: 24px;
    display: flex;
    float: right;
    z-index: 1;
    border: none;
    background: none;
    padding: 0;

    &:hover {
      color: color(gray, 700);
    }
  }

  .mat-mdc-dialog-title {
    border-bottom: 1px solid color(gray, 300);
    color: $heading-font-color !important;
    font-family: "Open Sans Bold" !important;
    font-size: 16px !important;
    margin: 0;
    padding: 12px 22px;

    &::before {
      display: none !important;
    }
  }

  .mat-mdc-dialog-content {
    margin: 0;
    padding: 16px 22px !important;
    font-size: 14px !important;
    font-family: "Open Sans" !important;
    position: relative;
    color: rgba(0, 0, 0, 0.87) !important;

    &.no-padding {
      padding: 0 !important;
    }

    .progress-bar {
      position: absolute;
      top: 0;
      left: 0;
    }

    p {
      margin: 0 0 16px 0;
    }
  }

  .mat-mdc-dialog-actions {
    margin: 0;
    padding: 20px;
    min-height: 40px;
    box-shadow: 0 -1px 0 0 rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: flex-end;
    gap: 16px;

    > div {
      flex-grow: 1;

      &:last-child {
        display: flex;
        justify-content: flex-end;
        gap: 8px;
      }
    }
  }
}

.overflow-visible-dialog .mat-mdc-dialog-container {
  overflow: visible;
}

.rounded-dialog .mat-mdc-dialog-container {
  border-radius: 10px;
}

.no-padding-dialog {
  overflow: hidden;
}

// dialog default widths
.sp-dialog,
.sp-post-preview,
.sp-delete-post,
.sp-admin-edit-partners-contact-info-dialog,
.sp-leave-dialog,
.sp-admin-move-fb-ad-account-dialog,
.sp-admin-edit-fb-ad-account-dialog,
.sp-admin-create-fb-ad-account-dialog,
.sp-admin-brand-details-dialog,
.sp-linkedin-disconnect {
  width: 512px;
}

.sp-admin-approve-calendar-results-dialog {
  width: 800px;
}

.sp-brand-campaign-box-preview {
  width: 380px;
}

.sp-post-preview {
  &--linkedin {
    width: 534px;
  }
}

.sp-google-ads-connection {
  width: 456px;
}

.sp-campaign-list-add-connection {
  width: 550px;
}

.sp-partner-campaign-budget-edit {
  width: 536px;
}

.sp-partner-campaign-budget-post-confirm {
  width: 784px;
}

.sp-schedule-post,
.sp-choose-address {
  width: 650px;
}

.sp-partner-account-access-request,
.sp-partner-campaign-publish-post,
.sp-brand-campaign-partner-status-chart {
  width: 850px;
}

.sp-brand-campaign-ad {
  width: 940px;
}

.sp-brand-campaign-promo-ads-whitelist {
  width: 1000px;
}

.sp-authorize-fb-ads {
  width: 360px;
}

.sp-brand-campaign-details-preview {
  width: 1200px;
}

.cdk-overlay-pane {
  &.no-actions-dialog {
    .mat-mdc-dialog-content {
      max-height: 85vh;
    }
  }

  &.no-padding-dialog {
    .mat-mdc-dialog-content {
      padding: 0 !important;
    }
  }
}

.sp-content-creation {
  max-height: 90vh;
}

.sp-linkedin-disconnect {
  .mat-mdc-dialog-surface {
    overflow: visible;
  }
}

.sp-brand-campaign-submit-check {
  width: 600px;
}
