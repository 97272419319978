@import "variables";

/* containers */
.ph-item {
  position: relative;
  overflow: hidden;

  &:after {
    content: "";
    display: block;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    animation: loading 2s ease-in-out infinite;
    background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 25%,
      rgba(255, 255, 255, 0.4) 50%,
      rgba(255, 255, 255, 0) 75%
    );
  }
}

@keyframes loading {
  from {
    left: -100%;
  }
  to {
    left: 100%;
  }
}

.ph-row {
  display: flex !important;
  flex-wrap: wrap !important;
  gap: 16px !important;
}

.ph-v-center {
  align-items: center !important;
}

.ph-full-width {
  width: 100%;
}

/* width */
.ph-col-12 {
  max-width: 100%;
}
.ph-col-11 {
  max-width: 91.66%;
}
.ph-col-10 {
  max-width: 83.33%;
}
.ph-col-9 {
  max-width: 75%;
}
.ph-col-8 {
  max-width: 66%;
}
.ph-col-7 {
  max-width: 58.33%;
}
.ph-col-6 {
  max-width: 50%;
}
.ph-col-5 {
  max-width: 41.66%;
}
.ph-col-4 {
  max-width: 33%;
}
.ph-col-3 {
  max-width: 25%;
}
.ph-col-2 {
  max-width: 16.66%;
}
.ph-col-1 {
  max-width: 8.33%;
}

.ph-col-12,
.ph-col-11,
.ph-col-10,
.ph-col-9,
.ph-col-8,
.ph-col-7,
.ph-col-6,
.ph-col-5,
.ph-col-4,
.ph-col-3,
.ph-col-2,
.ph-col-1 {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
}

/* padding */
.ph-padding-8 {
  padding: 8px;
}
.ph-padding-16 {
  padding: 16px;
}
.ph-padding-24 {
  padding: 24px;
}

/* distance between elements */
.ph-gap-small {
  gap: 8px !important;
}
.ph-gap-big {
  gap: 24px !important;
}

/* elements */
.ph-avatar {
  background-color: color(gray, 200);
  border-radius: 50%;
  width: 100%;
  height: 100%;
}

.ph-image {
  background-color: color(gray, 200);
  padding-bottom: 33%;
  width: 100%;
  display: block;
  border-radius: 2px;
}

.ph-title {
  background-color: color(gray, 200);
  height: 24px;
  width: 70%;
  display: block;
  border-radius: 2px;
}

.ph-text {
  background-color: color(gray, 200);
  height: 12px;
  display: block;
  border-radius: 2px;
}

.ph {
  background-color: color(gray, 200) !important;
  color: color(gray, 200) !important;
  border: none !important;
}

.ph-chip {
  height: 29px;
  width: 80%;
  background-color: color(gray, 200) !important;
}

/* styles */
.ph-border-box {
  border: 1px solid color(gray, 200);
  box-shadow: 0 2px 4px color(gray, 200);
}
