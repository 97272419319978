.text {
  &--caption {
    font-family: "Open Sans";
    font-weight: 700;
    font-size: 14px;
  }

  &--label {
    font-family: "Open Sans";
    font-weight: 600;
    font-size: 11px;

    &--active {
    }
  }
}
